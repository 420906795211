.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import "~owl.carousel/dist/assets/owl.carousel.min.css";
@import "~owl.carousel/dist/assets/owl.theme.default.min.css";

.offcanvas {
  transition: top 0.5s ease; /* Smooth transition */
  background-color: #14457f !important;
}


.offcanvas.sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1050; 
  background-color: #fff; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
  height: 50px!important;
  transition-duration: 0.5s;
}

.css-13cymwt-control{
  border: none !important;
}

.footer-links ul.footl li::first-letter {
  text-transform: uppercase;
}

.footer-links ul.footl li {
  text-transform: lowercase;
}

@media screen and (max-width:768px) {
  .select-tour-type select{
      color: #fff!important;
      width: 100%;
  }
}